import Hero from "./components/Hero";
import Experiences from "./components/Experiences";
import Projects from "./components/Projects";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="bg-neutral-800 text-gray-300 relative">
      <Hero />
      <Experiences />
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
